import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'
import { AccessDenied } from '../AccessDenied'

interface IProps {
    component: any,
    path: any,
    exact?: boolean,
    roles?: string[]
}

interface IState {
    ready: boolean,
    authenticated: boolean,
    user: any | null;
}

export default class AuthorizeRoute extends Component<IProps, IState> {
    _subscription: any;

    constructor(props: IProps) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false,
            user: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated, user } = this.state;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`
        if (!ready) {
            return <div></div>;
        } else {
            const { component: Component, roles, ...rest } = this.props;
            return <Route {...rest}
                render={(props) => {
                    if (authenticated) {
                        if (!roles || (roles && user && roles.some(r => [user.role].includes(r)))) {
                            return <Component {...props} />
                        }
                        else {
                            return <AccessDenied />
                        }
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
        }
    }

    async populateAuthenticationState() {
        const [authenticated, user] = await Promise.all<boolean, any>([authService.isAuthenticated(), authService.getUser()])
        this.setState({ ready: true, authenticated, user });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false, user: null });
        await this.populateAuthenticationState();
    }
}

import React, { Component } from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';

interface IState {
    isHidden: boolean;
}

interface IProps {
    title: string;
    subText: string;
    primaryButtonText: string;
    defaultButtonText: string;
    onPrimaryButtonClicked?: () => void;
}

export class ConfirmAction extends React.Component<IProps, IState> {
    public state: IState = { isHidden: true };

    public render() {
        const { isHidden } = this.state;
        const { title, subText, primaryButtonText, defaultButtonText } = this.props;
        return (
            <>
                <Dialog
                    hidden={isHidden}
                    onDismiss={this._closeDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: title,
                        subText: subText,
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } },
                    }}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={this._primaryButtonClicked} text={primaryButtonText} />
                        <DefaultButton onClick={this._closeDialog} text={defaultButtonText} />
                    </DialogFooter>
                </Dialog>
            </>
        );
    }

    show() {
        this._showDialog();
    }

    private _showDialog = (): void => {
        this.setState({ isHidden: false });
    };

    private _closeDialog = (): void => {
        this.setState({ isHidden: true });
    };

    private _primaryButtonClicked = (): void => {
        const { onPrimaryButtonClicked } = this.props;
        this._closeDialog();
        onPrimaryButtonClicked && onPrimaryButtonClicked();
    };
}
import React, { Component } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';

export class NotFound extends Component {
    static displayName = NotFound.name;

    render() {
        return (
            <Stack>
                <span>Page not found</span>
            </Stack>
        );
    }
}

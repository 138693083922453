import React, { Component } from 'react';
import { Stack, IStackStyles, IStackTokens, IStackItemStyles } from '@fluentui/react/lib/Stack';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { Text } from '@fluentui/react/lib/Text';
import { TopNav } from './TopNav';
import { SideNav } from './SideNav';
import { Image } from '@fluentui/react/lib/Image';
import CapitalLogo from '../images/capital_logo.png'
import NovascriptLogo from '../images/novascript_logo.png'
import authService from './api-authorization/AuthorizeService';
import { AuthorizeLayout } from './api-authorization/AuthorizeLayout';
import { isIE } from 'react-device-detect';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';

const stackItemStyles: IStackItemStyles = {
    root: {
        color: DefaultPalette.black,
        height: 70,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        padding: 10
    }
};

interface IProps {
}

interface IState {
    ready: boolean;
    authenticated: boolean;
    user: any | null;
    browserWarningDismissed: boolean;
}

export class Layout extends Component<IProps, IState> {
    static displayName = Layout.name;
    _subscription: any;

    constructor(props: IProps) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false,
            user: null,
            browserWarningDismissed: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateAuthenticationState() {
        const [authenticated, user] = await Promise.all<boolean, any>([authService.isAuthenticated(), authService.getUser()])
        this.setState({ ready: true, authenticated, user });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false, user: null });
        await this.populateAuthenticationState();
    }

    render() {
        const { ready, browserWarningDismissed } = this.state;
        if (!ready) {
            return (
                <AuthorizeLayout>
                    {this.props.children}
                </AuthorizeLayout>
            );
        }
        else {

            const logo = process.env.REACT_APP_Application_Name === "Novascript" ? NovascriptLogo : CapitalLogo;
            return (
                <Stack styles={{
                    root: {
                        height: '100vh'
                    }
                }}>
                    <Stack.Item>
                        <TopNav />
                    </Stack.Item>
                    <Stack.Item>
                        {(isIE && !browserWarningDismissed) && (
                            <MessageBar
                                messageBarType={MessageBarType.warning}
                                isMultiline={false}
                                onDismiss={() => { this.setState({ browserWarningDismissed: true }) }}
                                dismissButtonAriaLabel="Close"
                            >
                                <b>We noticed you're using an outdated browser</b>. For the best experience we recommend you upgrade to a modern browser such as Chrome, Firefox, Safari, Opera, or Edge.
                            </MessageBar>
                        )}
                    </Stack.Item>
                    <Stack.Item>
                        <Stack>
                            <Stack.Item styles={stackItemStyles}>
                                <Image src={logo} height={60} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item verticalFill styles={{
                        root: {
                            borderTop: '1px solid #eee'
                        }
                    }}>
                        <Stack horizontal verticalFill>
                            <Stack.Item verticalFill disableShrink
                                styles={{
                                    root: {
                                        boxSizing: 'border-box',
                                        borderRight: '1px solid #eee',
                                        overflowY: 'auto'
                                    }
                                }}>
                                <SideNav />
                            </Stack.Item>
                            <Stack.Item verticalFill grow>
                                {this.props.children}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            );
        }
    }
}

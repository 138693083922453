import React, { Component, createRef } from 'react';
import { EntityView } from './EntityView';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { Link as FabricLink, ILinkProps } from '@fluentui/react/lib/Link';
import { Link } from "react-router-dom";
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { IItemPanelField, ItemPanelFieldType, ItemPanel } from './ItemPanel';
import moment from 'moment-timezone';
import { PersonaSize, Persona } from '@fluentui/react/lib/Persona';
import authService from './api-authorization/AuthorizeService';
import { ApplicationRoles } from './api-authorization/ApiAuthorizationConstants';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';

export interface IJob {
    id: string;
    name: string;
}

interface IProps {

}

interface IState {
    isAuthenticated: boolean,
    user: any | null
}

const jobColumns: IColumn[] = [
    //{ key: 'id', name: 'Id', fieldName: 'id', minWidth: 150, maxWidth: 250, isResizable: true, isCollapsable: false },
    {
        key: 'name', name: 'Name', fieldName: 'name', minWidth: 200, maxWidth: 350, isResizable: true, isCollapsable: false, onRender: (item: IJob) => {
            return (
                <FabricLink href={'/jobs/' + item.id} as={RouterLink}>
                    {item.name}
                </FabricLink>
            );
        },
    },
    {
        key: 'created', name: 'Created', minWidth: 100, maxWidth: 200, isResizable: true,
        onRender: (item: any) => {
            return moment(item.created).tz(moment.tz.guess()).format('LLL');
        }
    },
    {
        key: 'createdBy', name: 'Created By', minWidth: 100, maxWidth: 200, isResizable: true,
        onRender: (item: any) => {
            const personaText = `${item.createdBy.firstName} ${item.createdBy.lastName}`;
            return <Persona text={personaText} size={PersonaSize.size24} />
        }
    },
];


const RouterLink = (props: ILinkProps) => {
    return (
        <Link className={props.className} to={{ pathname: props.href as string }}>
            {props.children}
        </Link>
    );
}

const Breadcrumb: IBreadcrumbItem[] = [
    { text: "Jobs", key: 'breadcrumbJobs', isCurrentItem: true }
];


export class Jobs extends Component<IProps, IState>  {
    static displayName = Jobs.name;
    _authSubscription: any;
    private _entityViewRef = createRef<EntityView>();
    private _itemPanelRef = createRef<ItemPanel>()

    constructor(props: IProps) {
        super(props);

        this.state = {
            isAuthenticated: false,
            user: null
        };
    }

    componentDidMount() {
        this._authSubscription = authService.subscribe(() => this.populateAuthState());
        this.populateAuthState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._authSubscription);
    }

    async populateAuthState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            user: user
        });
    }

    private _itemPanelFields = (): IItemPanelField[] => {
        const { user } = this.state;
        return [
            { name: "name", type: ItemPanelFieldType.Text, props: { label: "Name", required: true } },
            ...(user && [user.role].includes(ApplicationRoles.Internal) ? [
                { name: "externalUsers", type: ItemPanelFieldType.ExternalPeoplePicker, props: { label: "External Users", required: false } }
            ] : [])
        ];
    }

    private _setCommandBarItems: any = (commandBarItems: ICommandBarItemProps[], selectionCount: number) => {
        if (selectionCount === 1) {
            return [this._editCommandBarItem].concat(commandBarItems);
        }
        return commandBarItems;
    }

    private _editCommandBarItem: ICommandBarItemProps = {
        key: 'edit',
        buttonStyles: EntityView.commandBarButtonStyles,
        onClick: () => { this._editSelectedJob() },
        text: 'Edit',
        iconProps: { iconName: 'Edit' },
    }

    private _getSelectedJob = () => {
        const items = this._entityViewRef.current?.getSelectedItems();
        if (items && items.length > 0) {
            return items[0];
        }
        return null;
    }

    private _editSelectedJob = async () => {
        let job = this._getSelectedJob();
        job = await this.getJobData(job.id);
        this._itemPanelRef.current?.show(job);
    }

    private _entityViewEndpoint = (): string => {
        const { user } = this.state;
        return user && [user.role].includes(ApplicationRoles.Internal) ? "api/jobs" : "api/me/jobs";
    }

    async updateJob(item: any) {
        //TODO: validation.
        const job = this._getSelectedJob();
        const token = await authService.getAccessToken();
        const response = await fetch(`${this._entityViewEndpoint()}(${job.id})`, {
            method: 'PUT',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(item)
        });
        const status = await response.status
        if (status === 204) {
            this._entityViewRef.current?.populateEntityData();
        }
        //TODO: handle failure and display message.
    }

    async getJobData(jobId: string): Promise<any> {
        //TODO: validation.
        const token = await authService.getAccessToken();
        const response = await fetch(`${this._entityViewEndpoint()}(${jobId})?$select=id,name&$expand=jobUsers($select=user;$expand=user($select=id,firstName,lastName,email))`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        const jobUsers: any[] = data.jobUsers;
        const users = jobUsers.map(ju => ju.user);
        data.externalUsers = users;
        return data;
        //TODO: handle failure and display message.
    }

    private _getEntityQuery = (orderBy?: IColumn): string => {
        let orderByClause = "";
        let orderByCreatedByClause = "";
        if (orderBy) {
            const direction = orderBy.isSortedDescending ? " desc" : "";
            if (orderBy.key === "createdBy") {
                orderByCreatedByClause = `;$orderby=firstName${direction}`;
            }
            else {
                orderByClause = `&$orderby=${orderBy.key}${direction}`;
            }
        }
        return `?$select=id,name,created${orderByClause}&$expand=createdby($select=firstName,lastName,email${orderByCreatedByClause})`;
    }


    render() {
        const { user } = this.state;
        if (!user) {
            return (<></>);
        }
        else {
            return (
                <EntityView
                    ref={this._entityViewRef}
                    entityName='job'
                    idIsString={false}
                    breadcrumb={Breadcrumb}
                    entityColumns={jobColumns}
                    itemPanelFields={this._itemPanelFields()}
                    onSetCommandBarItems={this._setCommandBarItems}
                    noItemsMessage="Click New to add jobs"
                    odataEndpoint={this._entityViewEndpoint()}
                    getEntityQuery={this._getEntityQuery}
                >
                    <ItemPanel
                        ref={this._itemPanelRef}
                        fields={this._itemPanelFields()}
                        headerText="Edit job"
                        primaryButtonText="Save"
                        defaultButtonText="Cancel"
                        onPrimaryButtonClicked={(item) => this.updateJob(item)}
                    />
                </EntityView>
            );
        }
    }
}

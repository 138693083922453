import React, { Component } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';

export class AccessDenied extends Component {
    static displayName = AccessDenied.name;

    render() {
        return (
            <Stack>
                <span>Access denied</span>
            </Stack>
        );
    }
}

import React, { Component, createRef } from 'react';
import { EntityView } from './EntityView';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IItemPanelField, ItemPanelFieldType, ItemPanel } from './ItemPanel';
import { IComboBoxProps } from '@fluentui/react';
import { PersonaSize, Persona } from '@fluentui/react/lib/Persona';
import authService from './api-authorization/AuthorizeService';

const userColumns: IColumn[] = [
    {
        key: 'name', name: 'Full Name', minWidth: 150, isResizable: true,
        onRender: (item: any) => {
            const personaText = `${item.firstName} ${item.lastName}`;
            return <Persona text={personaText} size={PersonaSize.size24} />
        }
    },
    { key: 'email', name: 'Email', fieldName: 'email', minWidth: 200, isResizable: true },
    { key: 'roles', name: 'Role', fieldName: 'roles', minWidth: 125, isResizable: true },
];

const Breadcrumb: IBreadcrumbItem[] = [
    { text: "Users", key: 'breadcrumbUsers', isCurrentItem: true }
];

const itemPanelFields: IItemPanelField[] = [
    { name: "firstName", type: ItemPanelFieldType.Text, props: { label: "First Name", required: true } },
    { name: "lastName", type: ItemPanelFieldType.Text, props: { label: "Last Name", required: true } },
    { name: "email", type: ItemPanelFieldType.Text, props: { label: "Email", required: true } },
    { name: "role", type: ItemPanelFieldType.ChoiceGroup, props: { label: "Role", required: true, options: [{ key: "External", text: "External" }, { key: "Internal", text: "Internal" }], defaultSelectedKey: "External" } },
];

const itemPanelEditFields: IItemPanelField[] = [
    { name: "firstName", type: ItemPanelFieldType.Text, props: { label: "First Name", required: true } },
    { name: "lastName", type: ItemPanelFieldType.Text, props: { label: "Last Name", required: true } },
    { name: "password", type: ItemPanelFieldType.Text, props: { label: "Reset Password (leave blank to keep existing password)", type: "password" } },
    { name: "isBlocked", type: ItemPanelFieldType.Checkbox, props: { label: "Block user from signing in" } },
];

export class Users extends Component {
    static displayName = Users.name;
    private _entityViewRef = createRef<EntityView>();
    private _itemPanelRef = createRef<ItemPanel>()

    private _entityDataLoaded: any = (users: any) => {
        const usersArray: any[] = users.value;
        usersArray.map(user => {
            const userRoles: any[] = user.userRoles;
            const roles = userRoles.map(ur => ur.role.name).join(", ");
            user.roles = roles;
        });
        return usersArray;
    }

    private _setCommandBarItems: any = (commandBarItems: ICommandBarItemProps[], selectionCount: number) => {
        if (selectionCount === 1) {
            return [this._editCommandBarItem].concat(commandBarItems);
        }
        return commandBarItems;
    }

    private _editCommandBarItem: ICommandBarItemProps = {
        key: 'edit',
        buttonStyles: EntityView.commandBarButtonStyles,
        onClick: () => { this._editSelectedUser() },
        text: 'Edit',
        iconProps: { iconName: 'Edit' },
    }

    private _getSelectedUser = () => {
        const items = this._entityViewRef.current?.getSelectedItems();
        if (items && items.length > 0) {
            return items[0];
        }
        return null;
    }

    private _editSelectedUser = async () => {
        let job = this._getSelectedUser();
        this._itemPanelRef.current?.show(job);
    }

    async updateUser(item: any) {
        //TODO: validation.
        const user = this._getSelectedUser();
        const token = await authService.getAccessToken();
        const response = await fetch(`api/users('${user.id}')`, {
            method: 'PUT',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(item)
        });
        const status = await response.status
        if (status === 204) {
            this._entityViewRef.current?.populateEntityData();
        }
        //TODO: handle failure and display message.
    }

    private _getEntityQuery = (orderBy?: IColumn): string => {
        let orderByClause = "";
        let orderByRolesClause = "";
        if (orderBy) {
            const direction = orderBy.isSortedDescending ? " desc" : "";
            if (orderBy.key === "roles") {
                orderByRolesClause = `;$orderby=name${direction}`;
            }
            else if (orderBy.key === "name") {
                orderByClause = `&$orderby=firstName${direction}`;
            }
            else {
                orderByClause = `&$orderby=${orderBy.key}${direction}`;
            }
        }
        return `?$select=id,firstName,lastName,email,isBlocked${orderByClause}&$expand=userRoles($select=role;$expand=role($select=name${orderByRolesClause}))`;
    }

    render() {
        return (
            <EntityView
                ref={this._entityViewRef}
                entityName='user'
                idIsString={true}
                breadcrumb={Breadcrumb}
                entityColumns={userColumns}
                itemPanelFields={itemPanelFields}
                onSetCommandBarItems={this._setCommandBarItems}
                noItemsMessage="Click New to add users"
                onEntityDataLoaded={this._entityDataLoaded}
                odataEndpoint='api/users'
                getEntityQuery={this._getEntityQuery}
            >
                <ItemPanel
                    ref={this._itemPanelRef}
                    fields={itemPanelEditFields}
                    headerText="Edit user"
                    primaryButtonText="Save"
                    defaultButtonText="Cancel"
                    onPrimaryButtonClicked={(item) => this.updateUser(item)}
                />
            </EntityView>
        );
    }
}

import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import { Nav, INavLink, INavProps } from '@fluentui/react/lib/Nav';
import { Icon } from '@fluentui/react/lib/Icon';
import authService from './api-authorization/AuthorizeService';
import { ApplicationRoles } from './api-authorization/ApiAuthorizationConstants';

interface IProps {

}

interface IState {
    isAuthenticated: boolean,
    user: any | null
}

export class SideNav extends Component<IProps, IState> {
    _authSubscription: any;
    constructor(props: IProps) {
        super(props);

        this.state = {
            isAuthenticated: false,
            user: null
        };
    }

    componentDidMount() {
        this._authSubscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._authSubscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            user: user
        });
    }

    onRenderLink(props: any) {
        return <Link className={props.className} style={{ color: 'inherit', boxSizing: 'border-box' }} to={props.href}>
            <span style={{ display: 'flex' }}>
                {!!props.iconProps && <Icon style={{ margin: '0 4px' }} {...props.iconProps} />}
                {props.children}
            </span>

        </Link>;
    }

    render() {
        const { isAuthenticated, user } = this.state;
        return (
            <Nav
                linkAs={this.onRenderLink}
                styles={{
                    root: {
                        width: 210
                    }
                }}
                groups={[
                    {
                        links: [
                            {
                                name: 'Home',
                                url: '/',
                                key: 'home',
                                isExpanded: true
                            },
                            ...(isAuthenticated ? [
                                {
                                    name: 'Jobs',
                                    url: '/jobs',
                                    key: 'jobs',
                                    isExpanded: true
                                }] : []),
                            ...(user && [user.role].includes(ApplicationRoles.Internal) ? [
                                {
                                    name: 'Users',
                                    url: '/users',
                                    key: 'users',
                                    isExpanded: true
                                }] : [])
                        ]
                    }
                ]}
            />
        );
    }

}

import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Jobs } from './components/Jobs';
import { Job } from './components/Job';
import { Users } from './components/Users';
import { NotFound } from './components/NotFound';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths, ApplicationRoles } from './components/api-authorization/ApiAuthorizationConstants';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { initializeFileTypeIcons } from '@uifabric/file-type-icons';
import { AuthorizeLayout } from './components/api-authorization/AuthorizeLayout';


import './custom.css'


initializeIcons();
initializeFileTypeIcons();

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Switch>
                <Route path={[ApplicationPaths.ApiAuthorizationPrefix]}>
                    <AuthorizeLayout>
                        <Switch>
                            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                        </Switch>
                    </AuthorizeLayout>
                </Route>
                <Route exact path={["/jobs", "/jobs/:jobId", "/users", "/"]}>
                    <Layout>
                        <Switch>
                            <AuthorizeRoute exact path='/' component={Home} />
                            <AuthorizeRoute exact path='/jobs' component={Jobs} />
                            <AuthorizeRoute exact path='/jobs/:jobId' component={Job} />
                            <AuthorizeRoute exact path='/users' component={Users} roles={[ApplicationRoles.Internal]} />
                        </Switch>
                    </Layout>
                </Route>
                <Route path="/*">
                    <AuthorizeLayout>
                        <Switch>
                            <Route component={NotFound} />
                        </Switch>
                    </AuthorizeLayout>
                </Route>
            </Switch>

        );
    }
}

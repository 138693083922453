import React, { Component } from 'react';
import { Panel, PanelType, IPanelStyles } from '@fluentui/react/lib/Panel';
import { TextField, ITextFieldProps } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton, IButtonProps, IButtonStyles } from '@fluentui/react/lib/Button';
import { Stack } from '@fluentui/react/lib/Stack';
import { IComboBoxProps, ComboBox, IComboBoxOption, IChoiceGroupOption, IChoiceGroupProps, ChoiceGroup, IPeoplePickerProps, Checkbox, ICheckboxProps } from '@fluentui/react';
import { ExternalPeoplePicker, IExternalPeoplePickerProps } from './ExternalPeoplePicker';
import { IPersonaProps } from '@fluentui/react/lib/Persona';

export interface IItemPanelField {
    name: string;
    type: ItemPanelFieldType;
    props: ITextFieldProps | IComboBoxProps | IChoiceGroupProps | IPeoplePickerProps;
}

export enum ItemPanelFieldType {
    Text = 0,
    ComboBox = 1,
    ChoiceGroup = 2,
    ExternalPeoplePicker = 3,
    Checkbox = 4
}

interface IState {
    isOpen: boolean;
    item: any;
    errors: Map<string, string>;
    existingItem?: any;
}

interface IProps {
    headerText: string;
    primaryButtonText: string;
    defaultButtonText: string;
    fields: IItemPanelField[];
    onPrimaryButtonClicked?: (item: object) => void;
}

export class ItemPanel extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            isOpen: false,
            errors: new Map<string, string>(),
            item: {}
        };
    }

    public render() {
        const { isOpen, existingItem, errors } = this.state;
        const { headerText, primaryButtonText, defaultButtonText, fields } = this.props;
        return (
            <>
                <Panel
                    styles={{
                        root: {
                            marginTop: '50px'
                        }
                    }}
                    type={PanelType.medium}
                    isOpen={isOpen}
                    onDismiss={this._hidePanel}
                    headerText={headerText}
                    closeButtonAriaLabel="Close"
                >
                    <Stack tokens={{ childrenGap: 25 }}>
                        {fields.map((field) => {
                            const existingValue = existingItem && existingItem[field.name];
                            const errorMessage = errors.has(field.name) ? errors.get(field.name) : undefined;
                            switch (field.type) {
                                case ItemPanelFieldType.Text: {
                                    return <TextField {...field.props as ITextFieldProps} onChange={this._handleTextFieldChange(field.name)} defaultValue={existingValue as string} errorMessage={errorMessage} />;
                                }
                                case ItemPanelFieldType.ComboBox: {
                                    return <ComboBox {...field.props as IComboBoxProps} onChange={this._handleComboBoxChange(field.name)} />;
                                }
                                case ItemPanelFieldType.ChoiceGroup: {
                                    return <ChoiceGroup {...field.props as IChoiceGroupProps} onChange={this._handleChoiceGroupChange(field.name)} />;
                                }
                                case ItemPanelFieldType.Checkbox: {
                                    const defaultCheckedValue = existingValue ? existingValue as boolean : false;
                                    return <Checkbox {...field.props as ICheckboxProps} defaultChecked={defaultCheckedValue} onChange={this._handleCheckboxChange(field.name)} />;
                                }
                                case ItemPanelFieldType.ExternalPeoplePicker: {
                                    const defaultSelectedItems = existingValue && (existingValue as any[]).map(x => ({ itemID: x.id, primaryText: `${x.firstName} ${x.lastName}`, secondaryText: x.email }) as IPersonaProps);
                                    return <ExternalPeoplePicker {...field.props as IExternalPeoplePickerProps} onChange={this._handleExternalPeoplePickerChange(field.name)} defaultSelectedItems={defaultSelectedItems} />;
                                }
                            }
                        })}

                        <Stack horizontal>
                            <div>
                                <PrimaryButton styles={{ root: { marginRight: 8 } }} onClick={this._primaryButtonClicked}>{primaryButtonText}</PrimaryButton>
                                <DefaultButton onClick={this._hidePanel}>{defaultButtonText}</DefaultButton>
                            </div>
                        </Stack>
                    </Stack>
                </Panel>
            </>
        );
    }

    private _handleTextFieldChange(name: string) {
        return (ev: any, newValue?: string) => {
            const stringValue = newValue && newValue.trim();
            this._setFieldValueState(name, stringValue);
        }
    }

    private _handleComboBoxChange(name: string) {
        return (ev: any, option?: IComboBoxOption) => {
            this._setFieldValueState(name, option?.key);
        }
    }

    private _handleChoiceGroupChange(name: string) {
        return (ev: any, option?: IChoiceGroupOption) => {
            this._setFieldValueState(name, option?.key);
        }
    }

    private _handleCheckboxChange(name: string) {
        return (ev: any, checked?: boolean) => {
            this._setFieldValueState(name, checked);
        }
    }


    private _handleExternalPeoplePickerChange(name: string) {
        return (items?: any[]) => {
            if (items) {
                const newValue = items.map(item => item.itemID as string);
                this._setFieldValueState(name, newValue);
            }
            else {
                this._setFieldValueState(name, []);
            }
        }
    }

    private _setFieldValueState(fieldName: string, value?: any) {
        const { fields } = this.props;
        const { errors } = this.state;
        const field = fields.find(x => x.name === fieldName);

        errors.delete(fieldName);
        if (field && field.type === ItemPanelFieldType.Text && (field.props as any).required && (!value || !(value as string).trim())) {
            errors.set(field.name, "You can't leave this blank.");
        }

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [fieldName]: value
            },
            errors: errors
        }))
    }

    show(existingItem?: any) {
        this._showPanel(existingItem);
    }

    private _showPanel = (existingItem?: any): void => {
        const { fields } = this.props;
        let item: any = {};
        fields.map((field) => {
            if (field.type === ItemPanelFieldType.ChoiceGroup) {
                const props = field.props as IChoiceGroupProps;
                item[field.name] = props.defaultSelectedKey;
            }
            else if (field.type === ItemPanelFieldType.ComboBox) {
                const props = field.props as IComboBoxProps;
                item[field.name] = props.defaultSelectedKey;
            }
            if (existingItem) {
                const existingValue = existingItem[field.name];
                if (existingValue) {
                    if (field.type === ItemPanelFieldType.ExternalPeoplePicker) {
                        item[field.name] = (existingValue as any[]).map(x => x.id as string);
                    }
                    else {
                        item[field.name] = existingValue;
                    }
                }
            }
        });
        this.setState({ isOpen: true, item: item, existingItem: existingItem, errors: new Map<string, string>() });
    };

    private _hidePanel = (): void => {
        this.setState({ isOpen: false });
    };

    private _primaryButtonClicked = (): void => {
        const { fields, onPrimaryButtonClicked } = this.props;
        const { item, errors } = this.state;

        fields.map((field) => {
            const fieldValue = item[field.name];
            if ((field.props as any).required && field.type === ItemPanelFieldType.Text && (!fieldValue || !(fieldValue as string).trim())) {
                errors.set(field.name, "You can't leave this blank.");
            }
        });

        if (errors.size !== 0) {
            this.setState({ errors: errors });
            return;
        }
        this._hidePanel();
        onPrimaryButtonClicked && onPrimaryButtonClicked(item);
    };
}
import React, { Component } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react/lib/ScrollablePane';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { CompoundButton } from '@fluentui/react/lib/Button';
import { Separator } from '@fluentui/react/lib/Separator';
import { Redirect } from 'react-router-dom';

interface IState {
    redirectTo?: string;
}

interface IProps {
}

export class Home extends Component<IProps, IState> {
    static displayName = Home.name;

    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { redirectTo } = this.state;
        if (redirectTo) {
            return (
                <Redirect to={redirectTo} />
            );
        }

        return (
            <Stack verticalFill>
                <Stack verticalFill styles={{
                    root: {
                        position: 'relative',
                        background: DefaultPalette.white
                    }
                }}>
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <div style={{ paddingLeft: '25px' }}>
                            <h2>Welcome to {process.env.REACT_APP_Application_Name}</h2>
                            <span>Use this site to securely transfer files for your jobs.</span>
                            <Separator />
                            <div>
                                <CompoundButton primary secondaryText="View your jobs and files" onClick={() => { this.setState({ redirectTo: "/jobs" }) }}>
                                    Get Started
                                </CompoundButton>
                            </div>
                        </div>
                    </ScrollablePane>
                </Stack>
            </Stack>
        );
    }
}

import React, { Component, createRef, createContext } from 'react';
import { StorageContext } from './azure-storage/contexts/StorageContext';
import { EntityView } from './EntityView';
import authService from './api-authorization/AuthorizeService';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { mergeStyles, mergeStyleSets, registerIcons } from '@fluentui/react/lib/Styling';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { ProgressPanel } from './ProgressPanel';
import { tap, distinctUntilChanged, flatMap, mergeMap, distinct } from 'rxjs/operators';
import { Subscriber, Observable, merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BlobItemUpload, BlobStorageRequest } from './azure-storage/types/azure-storage';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { Stack, IProgressIndicatorStyles, IProgressIndicatorStyleProps, IStyleFunctionOrObject } from '@fluentui/react';
import { ImageIcon } from '@fluentui/react/lib/Icon';
import { css } from '@fluentui/react/lib/Utilities';
import { BlobTransferStatus } from './azure-storage/services/BlobStorageService';
import { Link } from '@fluentui/react/lib/Link';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { Icon } from '@fluentui/react/lib/Icon';
import moment, { Moment } from 'moment-timezone';
import { IItemPanelField, ItemPanelFieldType } from './ItemPanel';
import { PersonaSize, Persona } from '@fluentui/react/lib/Persona';
import { ApplicationRoles } from './api-authorization/ApiAuthorizationConstants';

interface IJob {
    id: string;
    name: string;
    files?: IJobFile[];
}

interface IJobFile {
    id: string;
    name: string;
    size: number;
    created: Date;
    blobUri: string;
    createdBy: IUser;
}

interface IUser {
    id: string;
    userName: string;
    email: string;
}

interface IProps extends RouteComponentProps<{ jobId: string }> {
}

interface IState {
    breadcrumb: IBreadcrumbItem[];
    uploads: BlobItemUpload[];
    isAuthenticated: boolean,
    user: any | null,
    redirectTo?: string;
}

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
});

const formatBytes = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const dm = i <= 1 ? 0 : 2;

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const itemPanelFields: IItemPanelField[] = [];

export class Job extends Component<IProps, IState> {
    static displayName = Job.name;
    static contextType = StorageContext;
    context!: React.ContextType<typeof StorageContext>;
    private _inputFileRef = createRef<HTMLInputElement>();
    private _progressPanelRef = createRef<ProgressPanel>();
    private _entityViewRef = createRef<EntityView>();
    private _uploadSubscription: any | undefined;
    private _authSubscription: any;
    private _abortController = new AbortController();

    constructor(props: IProps) {
        super(props);

        this.state = {
            breadcrumb: [
                { text: "Jobs", key: 'breadcrumbJobs', onClick: () => { this.setState({ redirectTo: "/jobs" }) } }
            ],
            uploads: [],
            isAuthenticated: false,
            user: null
        };
    }

    private _uploadFiles = (files: FileList | null) => {
        if (!files || !files.length) {
            return;
        }
        const { jobId } = this.props.match.params;
        const context = this.context;
        this._entityViewRef.current?.setFarCommandBarItems([this._uploadingCommandBarItem]);
        this._progressPanelRef.current?.show();
        context.uploads.uploadItems(files, this._abortController.signal);
    }

    private _downloadSelected = async () => {
        let items = this._entityViewRef.current?.getSelectedItems();
        if (items) {
            const downloads = await Promise.all(items.map(async (item) => await this._getFileDownloadLink(item.id)));
            const downloadNext = (index: number) => {
                if (index >= downloads.length) {
                    return;
                }
                const download = downloads[index];
                download.click();
                setTimeout(() => downloadNext(index + 1), 500);
            }
            downloadNext(0);
        }
    }

    private _getFileDownloadLink = async (fileId: string): Promise<HTMLAnchorElement> => {
        let sas = await this.getFileDownloadSas(fileId);
        return this.createDownloadLink(sas.storageUri);
    }

    createDownloadLink(url: string): HTMLAnchorElement {
        var link = document.createElement('a');
        link.href = url;
        link.download = url.substr(url.lastIndexOf('/') + 1);
        return link;
    }

    private _showFileDialog = () => { this._inputFileRef.current?.click(); }

    private _jobColumns: IColumn[] = [
        //{ key: 'id', name: 'Id', fieldName: 'id', minWidth: 125, maxWidth: 200, isResizable: true },
        {
            key: 'icon',
            name: 'Type',
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item: any) => (<Icon {...getFileTypeIconProps({ extension: this._getExtension(item.name), size: 16 })} />)
        },
        {
            key: 'name', name: 'Name', fieldName: 'name', minWidth: 100, maxWidth: 300, isResizable: true,
            onRender: (item: any) => (
                <Link onClick={async () => {
                    const download = await this._getFileDownloadLink(item.id);
                    download.click();
                }}>
                    {item.name}
                </Link>
            )
        },
        {
            key: 'size', name: 'File Size', minWidth: 100, maxWidth: 200, isResizable: true,
            onRender: (item: any) => {
                return formatBytes(item.size);
            }
        },
        {
            key: 'created', name: 'Created', minWidth: 100, maxWidth: 200, isResizable: true,
            onRender: (item: any) => {
                return moment(item.created).tz(moment.tz.guess()).format('LLL');
            }
        },
        {
            key: 'createdBy', name: 'Created By', minWidth: 100, maxWidth: 200, isResizable: true,
            onRender: (item: any) => {
                const personaText = `${item.createdBy.firstName} ${item.createdBy.lastName}`;
                return <Persona text={personaText} size={PersonaSize.size24} />
            }
        },
    ];

    private _getExtension(fileName: string): string {
        const extension = fileName.split('.').pop();
        return extension ? extension : "";
    }

    private _updateBreadcrumb(jobName: string): void {
        const updatedBreadcrumb: IBreadcrumbItem[] = [
            { text: "Jobs", key: 'breadcrumbJobs', onClick: () => { this.setState({ redirectTo: "/jobs" }) } },
            { text: jobName, key: 'breadcrumbJob', isCurrentItem: true },
        ];
        this.setState({ breadcrumb: updatedBreadcrumb });
    }

    private _entityDataLoaded: any = (job: any) => {
        this._updateBreadcrumb(job.name)
        return job.files;
    }

    private _setCommandBarItems: any = (commandBarItems: ICommandBarItemProps[], selectionCount: number) => {
        if (selectionCount > 0) {
            return selectionCount <= 10 ? [this._downloadCommandBarItem].concat(commandBarItems) : commandBarItems;
        }
        return [this._uploadCommandBarItem];
    }

    private _uploadCommandBarItem: ICommandBarItemProps = {
        key: 'upload',
        buttonStyles: EntityView.commandBarButtonStyles,
        onClick: () => { this._showFileDialog() },
        text: 'Upload',
        iconProps: { iconName: 'Upload' },
    }

    private _downloadCommandBarItem: ICommandBarItemProps = {
        key: 'download',
        buttonStyles: EntityView.commandBarButtonStyles,
        onClick: () => { this._downloadSelected() },
        text: 'Download',
        iconProps: { iconName: 'Download' },
    }

    private _uploadingCommandBarItem: ICommandBarItemProps = {
        key: 'uploading',
        buttonStyles: EntityView.commandBarButtonStyles,
        onClick: () => { this._progressPanelRef.current?.show() },
        text: 'Uploading',
        iconProps: { iconName: 'Sync' },
    }

    private _setUploadItems(items: BlobItemUpload[]) {
        const allUploadsCompleted = items.every(x => x.status === BlobTransferStatus.Completed);
        if (allUploadsCompleted) {
            this._entityViewRef.current?.setFarCommandBarItems();
        }
        this.setState({ uploads: items });
    }


    private _deleteFiles = async (items: any[]) => {
        await Promise.all(items.map((item) => this.deleteFile(item.id)));
        return true;
    }

    async getFileDownloadSas(fileId: string): Promise<BlobStorageRequest> {
        //TODO: validation.
        const { jobId } = this.props.match.params;
        const [token, user] = await Promise.all<any, any>([authService.getAccessToken(), authService.getUser()])
        const endpoint = user && [user.role].includes(ApplicationRoles.Internal) ? `api/jobs(${jobId})` : `api/me/jobs(${jobId})`;
        const response = await fetch(`${endpoint}/getdownloadsas(fileId=${fileId})`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        });
        const data = await response.json().then(data => data as BlobStorageRequest);
        return data;
        //TODO: handle failure and display message.
    }

    async addFile(item: BlobItemUpload) {
        //TODO: validation.
        const { jobId } = this.props.match.params;
        const [token, user] = await Promise.all<any, any>([authService.getAccessToken(), authService.getUser()])
        const endpoint = user && [user.role].includes(ApplicationRoles.Internal) ? `api/jobs(${jobId})` : `api/me/jobs(${jobId})`;
        const response = await fetch(`${endpoint}/addfile`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                blob: {
                    name: item.filename,
                    size: item.fileSize
                }
            })
        });
        const status = await response.status
        if (status === 201) {
            await this._entityViewRef.current?.populateEntityData();
        }
        //TODO: handle failure and display message.
    }

    async deleteFile(id: string) {
        const { jobId } = this.props.match.params;
        const [token, user] = await Promise.all<any, any>([authService.getAccessToken(), authService.getUser()])
        const endpoint = user && [user.role].includes(ApplicationRoles.Internal) ? `api/jobs(${jobId})` : `api/me/jobs(${jobId})`;
        const response = await fetch(`${endpoint}/deletefile`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                fileId: id
            })
        });
        const status = await response.status
        //TODO: handle failure and display message.
    }

    componentDidMount() {
        const { jobId } = this.props.match.params;
        this.context.shared.setBlobContainer(jobId);
        this._uploadSubscription = this.context.uploads.uploadedItems$.pipe(
            tap(items => this._setUploadItems(items)),
            mergeMap(items => items),
            filter(item => item.status === BlobTransferStatus.Completed),
            distinct(item => item.filename),
            tap(item => this.addFile(item))
        ).subscribe();
        this._authSubscription = authService.subscribe(() => this.populateAuthState());
        this.populateAuthState();
    }

    componentWillUnmount() {
        this._abortController.abort();
        this._uploadSubscription && this._uploadSubscription.unsubscribe();
        authService.unsubscribe(this._authSubscription);
    }

    async populateAuthState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            user: user
        });
    }

    private _entityViewEndpoint = (): string => {
        const { user } = this.state;
        const { jobId } = this.props.match.params;
        return user && [user.role].includes(ApplicationRoles.Internal) ? `api/jobs(${jobId})` : `api/me/jobs(${jobId})`;
    }

    private _getEntityQuery = (orderBy?: IColumn): string => {
        let orderByClause = "";
        let orderByCreatedByClause = "";
        if (orderBy) {
            const direction = orderBy.isSortedDescending ? " desc" : "";
            if (orderBy.key === "createdBy") {
                orderByCreatedByClause = `;$orderby=firstName${direction}`;
            }
            else {
                orderByClause = `$orderby=${orderBy.key}${direction};`;
            }
        }
        return `?$expand=files($select=id,name,size,created;${orderByClause}$expand=createdby($select=firstName,lastName,email${orderByCreatedByClause}))`;
    }

    private getErrorMessage(error: any): string {
        const message = error?.details?.message;
        return message ? `Error: ${message}` : "Error";
    }

    render() {
        const { uploads, user, redirectTo } = this.state;
        if (redirectTo) {
            return (
                <Redirect to={redirectTo} />
            );
        }
        if (!user) {
            return (<></>);
        }
        else {
            return (
                <>
                    <EntityView
                        ref={this._entityViewRef}
                        entityName='file'
                        idIsString={false}
                        breadcrumb={this.state.breadcrumb}
                        entityColumns={this._jobColumns}
                        itemPanelFields={itemPanelFields}
                        onEntityDataLoaded={this._entityDataLoaded}
                        onDeletingItems={this._deleteFiles}
                        onSetCommandBarItems={this._setCommandBarItems}
                        noItemsMessage="Click Upload to add files"
                        odataEndpoint={this._entityViewEndpoint()}
                        getEntityQuery={this._getEntityQuery}
                    >
                        <div style={{ display: 'none' }}>
                            <input
                                style={{ display: 'none' }}
                                ref={this._inputFileRef}
                                type="file"
                                multiple={true}
                                onChange={(e) => this._uploadFiles(e.target.files)}
                            />
                        </div>
                        <ProgressPanel
                            ref={this._progressPanelRef}
                            headerText="Upload Progress"
                            onDismiss={() => { }}
                        >
                            {uploads.map((item, i) => {
                                let progressIndicatorStyle: IStyleFunctionOrObject<IProgressIndicatorStyleProps, IProgressIndicatorStyles> = { root: { width: "200px" } };
                                if (item.error) {
                                    const itemDescriptionStyle: IStyleFunctionOrObject<IProgressIndicatorStyleProps, IProgressIndicatorStyles> = { itemDescription: { color: "red" } };
                                    progressIndicatorStyle = { ...progressIndicatorStyle, ...itemDescriptionStyle };
                                }
                                return (
                                    <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { overflow: "hidden", marginTop: "15px" } }}>
                                        <Stack.Item align="center">
                                            <Icon {...getFileTypeIconProps({ extension: this._getExtension(item.filename), size: 48 })} />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <ProgressIndicator
                                                styles={progressIndicatorStyle}
                                                label={item.filename}
                                                description={!item.error && item.progress < 100 ? item.progress + '%' : item.error ? this.getErrorMessage(item.error) : 'Done'}
                                                percentComplete={item.progress / 100.00}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                )
                            })}
                        </ProgressPanel>
                    </EntityView>
                </>
            );
        }
    }
}

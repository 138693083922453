import { from, OperatorFunction, Subject, Observable } from 'rxjs';
import { map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { BlobContainerRequest, BlobItemUpload, IBlobStorageTransfer } from '../types/azure-storage';
import { BlobSharedViewStateService } from './BlobSharedViewState';
import { BlobStorageService, BlobTransferStatus } from './BlobStorageService';

interface FileListWithAbortSignal {
    files: FileList;
    abortSignal: AbortSignal;
}

interface FileWithAbortSignal {
    file: File;
    abortSignal: AbortSignal;
}

export class BlobUploadsViewStateService {
    private uploadQueueInner$ = new Subject<FileListWithAbortSignal>();

    uploadedItems$ = this.uploadQueue$.pipe(
        mergeMap(f => this.uploadFile(f.file, f.abortSignal)),
        this.blobState.scanEntries()
    );

    get uploadQueue$(): Observable<FileWithAbortSignal> {
        return this.uploadQueueInner$
            .asObservable()
            .pipe(mergeMap(fileList => from(fileList.files)
                .pipe(map(f => ({ file: f, abortSignal: fileList.abortSignal }) as FileWithAbortSignal))
            ));
    }

    constructor(
        private blobStorage: BlobStorageService,
        private blobState: BlobSharedViewStateService
    ) { }

    uploadItems(files: FileList, abortSignal: AbortSignal): void {
        this.uploadQueueInner$.next({ files: files, abortSignal: abortSignal });
    }

    private uploadFile = (file: File, abortSignal: AbortSignal) =>
        this.blobState.getUploadStorageOptionsWithContainer().pipe(
            switchMap(options =>
                this.blobStorage
                    .uploadToBlobStorage(file, {
                        ...options,
                        filename: file.name
                    }, abortSignal)
                    .pipe(
                        this.mapUploadResponse(file, options),
                        this.blobState.finaliseBlobChange(options.containerName)
                    )
            )
        );

    private mapUploadResponse = (
        file: File,
        options: BlobContainerRequest
    ): OperatorFunction<IBlobStorageTransfer, BlobItemUpload> => source =>
            source.pipe(
                map(progress => ({
                    filename: file.name,
                    containerName: options.containerName,
                    bytesTransferred: progress.bytesTransferred,
                    fileSize: file.size,
                    progress: parseInt(((progress.bytesTransferred / file.size) * 100).toString(), 10),
                    error: progress.error,
                    status: progress.status
                })),
                startWith({
                    filename: file.name,
                    containerName: options.containerName,
                    bytesTransferred: 0,
                    fileSize: file.size,
                    progress: 0,
                    status: BlobTransferStatus.NotStarted
                })
            );
}

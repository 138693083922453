import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import { Stack, IStackStyles, IStackTokens, IStackItemStyles } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { IFontStyles } from '@fluentui/react/lib/Styling';
import { DefaultPalette, mergeStyles } from '@fluentui/react/lib/Styling';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { Button } from '@fluentui/react/lib/Button';

interface IProps {

}

interface IState {
    collapsed: boolean
}

const iconClass = mergeStyles({
    fontSize: 24,
    height: 24,
    width: 24,
    color: DefaultPalette.white
});

const stackStyles: IStackStyles = {
    root: {
        background: DefaultPalette.themePrimary
    }
};

const stackItemStyles: IStackItemStyles = {
    root: {
        color: DefaultPalette.white,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        padding: 10
    }
};

const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 10
};

export class TopNav extends Component<IProps, IState> {
    static displayName = TopNav.name;

    constructor(props: IProps) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Stack horizontal horizontalAlign="space-between" styles={stackStyles}>
                    <Stack>
                        <Stack.Item styles={stackItemStyles}>
                            <Stack horizontal tokens={horizontalGapStackTokens}>
                                <FontIcon iconName="CloudImportExport" className={iconClass} />
                                <Text variant={'mediumPlus'} nowrap block>
                                    {process.env.REACT_APP_Application_Name}
                                </Text>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    <Stack>
                    </Stack>
                    <Stack>
                        <Stack.Item styles={stackItemStyles}>
                            <Stack horizontal tokens={horizontalGapStackTokens}>
                                <a href="/Identity/Account/Manage"><FontIcon iconName="AccountManagement" className={iconClass} /></a>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </header>
        );
    }
}

import React, { Component, createRef, Children } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Stack } from '@fluentui/react/lib/Stack';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { DefaultButton, PrimaryButton, IButtonProps, IButtonStyles } from '@fluentui/react/lib/Button';
import { Breadcrumb, IBreadcrumbItem, IDividerAsProps } from '@fluentui/react/lib/Breadcrumb';
import { SelectionMode, Toggle } from '@fluentui/react/lib/index';
import { ShimmeredDetailsList } from '@fluentui/react/lib/ShimmeredDetailsList';
import { DetailsListLayoutMode, IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, Selection, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react/lib/ScrollablePane';
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { DefaultPalette, mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { ConfirmAction } from './ConfirmAction';
import { ItemPanel, IItemPanelField } from './ItemPanel';
import { Text as FabricText } from '@fluentui/react/lib/Text';
import { Image as FabricImage } from '@fluentui/react/lib/Image';

const classNames = mergeStyleSets({
    sticky: {
        paddingLeft: '25px',
    },
});

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }

    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );

    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true} stickyClassName={classNames.sticky}>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip,
            })}
        </Sticky>
    );
};

interface IEntityViewProps {
    entityName: string;
    idIsString: boolean;
    breadcrumb: IBreadcrumbItem[];
    entityColumns: IColumn[];
    itemPanelFields: IItemPanelField[];
    odataEndpoint: string;
    getEntityQuery?: (orderBy?: IColumn) => string;
    onEntityDataLoaded?: (data: any) => any;
    onSetCommandBarItems?: (commandBarItems: ICommandBarItemProps[], selectionCount: number) => ICommandBarItemProps[];
    onDeletingItems?: (items: any[]) => Promise<boolean>;
    noItemsMessage: string;
}

interface IEntityViewState {
    items: object[];
    columns: IColumn[];
    sortedColumn?: IColumn;
    isDataLoaded?: boolean;
    commandBarItems: ICommandBarItemProps[];
    farCommandBarItems?: ICommandBarItemProps[];
}

export class EntityView extends Component<IEntityViewProps, IEntityViewState> {
    static displayName = EntityView.name;
    private _selection: Selection;
    private _itemPanelRef = createRef<ItemPanel>()
    private _confirmDeleteActionRef = createRef<ConfirmAction>()

    static commandBarButtonStyles: IButtonStyles = {
        root: {
            backgroundColor: '#f4f4f4'
        },
        rootHovered: {
            color: '#212121',
            backgroundColor: '#eaeaea'
        }
    };

    constructor(props: IEntityViewProps) {
        super(props);

        const { onSetCommandBarItems, entityColumns } = this.props;
        const orderByColumn: IColumn | undefined = entityColumns.find(col => col.isSorted);
        this._selection = new Selection({
            onSelectionChanged: () => this._handleSelectionChanged(),
        });
        entityColumns.map(column => column.onColumnClick = this._onColumnClick);
        this.state = {
            items: [],
            columns: entityColumns,
            sortedColumn: orderByColumn,
            isDataLoaded: false,
            commandBarItems: onSetCommandBarItems ? onSetCommandBarItems(this._commandBarItemsNoSelection, 0) : this._commandBarItemsNoSelection
        };
    }

    componentDidMount() {
        this.populateEntityData();
    }

    getSelectedItems(): any[] {
        return this._selection.getSelection();
    }

    private deleteSelectedItems = async () => {
        let selectedItems: any[] = this._selection.getSelection();
        const { onDeletingItems } = this.props;
        let callbackDeletedItems = false;
        if (onDeletingItems) {
            callbackDeletedItems = await onDeletingItems(selectedItems);
        }
        if (!callbackDeletedItems) {
            for (let item of selectedItems) {
                await this.deleteItem(item.id);
            }
        }
        await this.populateEntityData();
        this._selection.setItems(this.state.items, true);
    }

    private _commandBarItemsNoSelection: ICommandBarItemProps[] = [
        {
            key: 'new',
            buttonStyles: EntityView.commandBarButtonStyles,
            onClick: () => { this._itemPanelRef.current?.show() },
            text: 'New',
            iconProps: { iconName: 'Add' },
        }
    ];

    private _commandBarItemsSelection: ICommandBarItemProps[] = [
        {
            key: 'delete',
            buttonStyles: EntityView.commandBarButtonStyles,
            onClick: () => { this._confirmDeleteActionRef.current?.show() },
            text: 'Delete',
            iconProps: { iconName: 'Delete' },
        }
    ];

    private _handleSelectionChanged(): void {
        const { onSetCommandBarItems } = this.props;
        const selectionCount = this._selection.getSelectedCount();
        let commandBarItems: ICommandBarItemProps[] = selectionCount > 0 ? this._commandBarItemsSelection : this._commandBarItemsNoSelection;
        if (onSetCommandBarItems) {
            commandBarItems = onSetCommandBarItems(commandBarItems, selectionCount);
        }
        this.setState({ commandBarItems: commandBarItems });
    }

    setFarCommandBarItems(commandBarItems?: ICommandBarItemProps[]) {
        this.setState({ farCommandBarItems: commandBarItems });
    }

    private _onColumnClick = async (ev: React.MouseEvent<HTMLElement>, column: IColumn): Promise<void> => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            }
            else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        await this.populateEntityData(currColumn);
        this.setState({
            columns: newColumns
        });
    }

    render() {
        const { items, columns, isDataLoaded, commandBarItems, farCommandBarItems } = this.state;
        const { breadcrumb, itemPanelFields, entityName, children, noItemsMessage } = this.props;
        return (
            <Stack verticalFill>
                <CommandBar styles={{
                    root: {
                        backgroundColor: '#f4f4f4'
                    }
                }}
                    items={commandBarItems}
                    farItems={farCommandBarItems}
                />
                <Stack verticalFill styles={{
                    root: {
                        position: 'relative',
                        background: DefaultPalette.white
                    }
                }}>
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <div style={{ paddingLeft: '25px' }}>
                            <Sticky stickyPosition={StickyPositionType.Header} stickyClassName={classNames.sticky}>
                                <Breadcrumb items={breadcrumb} />
                            </Sticky>
                            <ShimmeredDetailsList
                                setKey="set"
                                items={items}
                                columns={columns}
                                selectionMode={SelectionMode.multiple}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                constrainMode={ConstrainMode.unconstrained}
                                onRenderDetailsHeader={onRenderDetailsHeader}
                                selection={this._selection}
                                selectionPreservedOnEmptyClick={true}
                                enableShimmer={!isDataLoaded}
                                ariaLabelForShimmer="Loading items..."
                                ariaLabelForGrid="Item details"
                                listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                            />
                            {(isDataLoaded && items.length === 0) && (
                                <Stack horizontalAlign="center">
                                    <FabricImage src="https://static2.sharepointonline.com/files/fabric/office-ui-fabric-react-assets/images/emptyfolder/empty_list.svg" width={208} height={208} alt="Empty folder" />
                                    <FabricText variant={'large'} block>{noItemsMessage}</FabricText>
                                </Stack>
                            )}
                            <ItemPanel
                                ref={this._itemPanelRef}
                                fields={itemPanelFields}
                                headerText={'New ' + entityName}
                                primaryButtonText="Save"
                                defaultButtonText="Cancel"
                                onPrimaryButtonClicked={(item) => this.saveNewItem(item)}
                            />
                            <ConfirmAction
                                ref={this._confirmDeleteActionRef}
                                title="Delete?"
                                subText={this._selection.getSelectedCount() > 1 ? 'Are you sure you want to permanently delete these items?' : 'Are you sure you want to permanently delete this item?'}
                                primaryButtonText="Delete"
                                defaultButtonText="Cancel"
                                onPrimaryButtonClicked={this.deleteSelectedItems}
                            />
                            {children}
                        </div>
                    </ScrollablePane>
                </Stack>
            </Stack>
        );
    }

    async saveNewItem(item: object) {
        //TODO: validation.
        const { odataEndpoint } = this.props;
        const token = await authService.getAccessToken();
        const response = await fetch(odataEndpoint, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(item)
        });
        const status = await response.status
        if (status === 201) {
            this.populateEntityData();
        }
        //TODO: handle failure and display message.
    }

    async deleteItem(id: string) {
        const { odataEndpoint, idIsString } = this.props;
        const token = await authService.getAccessToken();
        const itemId = idIsString ? `'${id}'` : id;
        const response = await fetch(`${odataEndpoint}(${itemId})`, {
            method: 'DELETE',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        });
        const status = await response.status
        //TODO: handle failure and display message.
    }

    async populateEntityData(orderBy?: IColumn) {
        try {
            const { sortedColumn } = this.state;
            if (!orderBy && sortedColumn) {
                orderBy = sortedColumn;
            }
            const { odataEndpoint, getEntityQuery, onEntityDataLoaded } = this.props;
            const token = await authService.getAccessToken();
            const endpoint = getEntityQuery ? odataEndpoint + getEntityQuery(orderBy) : odataEndpoint;
            const response = await fetch(endpoint, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const data = await response.json();
            let entityData: any = {};

            if (onEntityDataLoaded) {
                entityData = onEntityDataLoaded(data);
            }
            else {
                entityData = data.value;
            }

            this.setState({
                isDataLoaded: true,
                items: entityData,
                sortedColumn: orderBy
            })
        }
        catch (e) {
        }
    }
}
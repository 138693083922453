import { Axios } from 'axios-observable';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BlobStorageRequest } from '../types/azure-storage';
import authService from '../../api-authorization/AuthorizeService';
import { AxiosRequestConfig } from 'axios';
import { ApplicationRoles } from '../../api-authorization/ApiAuthorizationConstants';

declare module 'axios' {
    export interface AxiosRequestConfig {
        user: any
    }
}

export class SasGeneratorService {
    getUploadSasToken(jobId: string): Observable<BlobStorageRequest> {
        return from(this.getConfig()).pipe(
            switchMap(config => {
                const endpoint = config.user && [config.user.role].includes(ApplicationRoles.Internal) ? `api/jobs(${jobId})` : `api/me/jobs(${jobId})`;
                return Axios.post<BlobStorageRequest>(`${endpoint}/createuploadsas`, null, config)
                    .pipe(map(res => res.data));
            })
        );
    }

    async getConfig(): Promise<AxiosRequestConfig> {
        const [token, user] = await Promise.all<any, any>([authService.getAccessToken(), authService.getUser()])
        return {
            headers: { Authorization: `Bearer ${token}` },
            user: user
        };
    }
}

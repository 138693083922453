import React, { Component } from 'react';
import { Stack, IStackStyles, IStackTokens, IStackItemStyles } from '@fluentui/react/lib/Stack';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { Text } from '@fluentui/react/lib/Text';
import { Image } from '@fluentui/react/lib/Image';


export class AuthorizeLayout extends Component {
    static displayName = AuthorizeLayout.name;

    render() {
        return (
            <Stack verticalAlign="center" horizontalAlign="center" styles={{
                root: {
                    height: '100vh'
                }
            }}>
                <Stack.Item verticalFill>
                    <Stack verticalFill verticalAlign="center">
                        {this.props.children}
                    </Stack>
                </Stack.Item>
            </Stack>
        );
    }
}

import React, { Component, createRef } from 'react';
import authService from './api-authorization/AuthorizeService';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { IBasePickerSuggestionsProps, NormalPeoplePicker, ValidationState, CompactPeoplePicker, IPeoplePickerProps } from '@fluentui/react/lib/Pickers';
import { Label } from '@fluentui/react';

const suggestionProps = {
    suggestionsHeaderText: 'Suggested People',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading'
}

interface IUser {
    firstName: string;
    lastName: string;
    id: string;
    email: string;
}

export interface IExternalPeoplePickerProps {
    label: string;
    required: boolean;
    defaultSelectedItems?: IPersonaProps[];
    onChange?: (items?: any[]) => void;
}

export class ExternalPeoplePicker extends Component<IExternalPeoplePickerProps> {
    private _peoplePickerRef = createRef<IPeoplePickerProps>()

    render() {
        const { required, label, onChange, defaultSelectedItems} = this.props;
        return (
            <div>
                <Label required={required}>{label}</Label>
                <NormalPeoplePicker
                    ref={this._peoplePickerRef}
                    onChange={onChange}
                    defaultSelectedItems={defaultSelectedItems}
                    onResolveSuggestions={this.searchPeople}
                    getTextFromItem={(item: IPersonaProps) => item.primaryText as string}
                    pickerSuggestionsProps={suggestionProps}
                />
            </div>)
    }

    async searchPeople(filterText: string, selectedItems?: IPersonaProps[], limitResults?: number) {
        try {
            const top = limitResults ? `&$top=${limitResults}` : "";
            const filterSelected = selectedItems ? selectedItems.map(item => ` and id ne '${item.itemID}'`).join("") : "";
            const token = await authService.getAccessToken();
            const response = await fetch(`api/users?$filter=userRoles/any(r: r/role/name eq 'external') and startsWith(FirstName, '${filterText}')${filterSelected}&$select=id,email,firstname,lastname${top}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const data = await response.json();
            const users = data.value as IUser[];
            return users.map(user => { return { itemID: user.id, primaryText: `${user.firstName} ${user.lastName}`, secondaryText: user.email } as IPersonaProps });
        }
        catch (e) {
            return [];
        }
    }

}
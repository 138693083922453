import React, { Component } from 'react';
import { Panel, PanelType, IPanelStyles } from '@fluentui/react/lib/Panel';
import { TextField } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton, IButtonProps, IButtonStyles } from '@fluentui/react/lib/Button';
import { Stack } from '@fluentui/react/lib/Stack';

interface IState {
    isOpen: boolean;
}

interface IProps {
    headerText: string;
    onDismiss?: () => void;
}

export class ProgressPanel extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            isOpen: false
        };
    }

    public render() {
        const { isOpen } = this.state;
        const { headerText, children } = this.props;
        return (
            <>
                <Panel
                    styles={{
                        root: {
                            marginTop: '50px'
                        }
                    }}
                    type={PanelType.smallFixedFar}
                    isBlocking={true}
                    isLightDismiss
                    isOpen={isOpen}
                    onDismiss={this._hidePanel}
                    headerText={headerText}
                    closeButtonAriaLabel="Close"
                >
                    <Stack tokens={{ childrenGap: 25 }}>
                        {children}
                    </Stack>
                </Panel>
            </>
        );
    }

    show() {
        this._showPanel();
    }

    private _showPanel = (): void => {
        this.setState({ isOpen: true });
    };

    private _hidePanel = (): void => {
        const { onDismiss } = this.props;
        this.setState({ isOpen: false });
        onDismiss && onDismiss();
    };
}